<template>
  <div class="claims static">
    <h2 class="title">Atencion y Reclamos</h2>
    <div class="form">
      <div class="form-group">
        <h2>Datos personales</h2>
        <div class="form-group-grid">
          <div class="form-item">
            <label>Teléfono con eXIM</label>
            <a-input v-model="phone" />
          </div>
          <div class="form-item">
            <label>Nombre</label>
            <a-input v-model="name" />
          </div>
          <div class="form-item">
            <label>Apellido</label>
            <a-input v-model="lastName" />
          </div>
          <div class="form-item">
            <label>DNI</label>
            <a-input v-model="dni" type="number" @change="onChangeDni" />
          </div>
        </div>
      </div>
      <div class="form-group">
        <h2>Domicilio</h2>
        <div class="form-group-grid">
          <div class="form-item">
            <label>Calle/Av./Jr.</label>
            <a-input v-model="calleAvJr" />
          </div>
          <div class="form-item">
            <label>Departamento</label>
            <a-input v-model="departamento" />
          </div>
          <div class="form-item">
            <label>Nombre Calle/Av./Jr.</label>
            <a-input v-model="nombreCalleAvJr" />
          </div>
          <div class="form-item">
            <label>Provincia</label>
            <a-input v-model="provincia" />
          </div>
          <div class="form-item">
            <label>Número</label>
            <a-input type="number" v-model="numero" @change="onChange" />
          </div>
          <div class="form-item">
            <label>Distrito</label>
            <a-input v-model="distrito" />
          </div>
        </div>
      </div>
      <div class="form-group">
        <h2>Forma de notificación</h2>
        <div class="form-group-grid">
          <div class="form-select">
            <label>Notificación por email</label>
            <a-checkbox :checked="notificacionPorEmail" @change="onChangeNotificacionPorEmail"></a-checkbox>
          </div>
          <div class="form-item">
            <label>Email</label>
            <a-input v-model="email" />
          </div>
          <div class="form-item">
            <label>Confirmar email</label>
            <a-input v-model="confirmEmail" />
          </div>
        </div>
      </div>
      <div class="form-group">
        <h2>Concepto de Reclamo</h2>
        <div class="form-group-grid">
          <div class="form-select">
            <label>Apelación de Reclamo</label>
            <a-checkbox :checked="apelacionReclamo" @change="onChangeApelacionReclamo"></a-checkbox>
          </div>
          <div class="form-item" v-if="apelacionReclamo">
            <label>Número de Reclamo</label>
            <a-input v-model="numReclamo" />
          </div>
          <div class="form-select">
            <label>Enviar email constancia</label>
            <a-checkbox :checked="enviarEmailConstancia" @change="onChangeEnviarEmailConstancia"></a-checkbox>
          </div>
          <div class="form-item">
            <label>Materia de Reclamo</label>
            <a-input v-model="materiaDeReclamo" />
          </div>
          <div class="form-item">
            <label>Solicitud Concreta</label>
            <a-input v-model="solicitudConcreta" />
          </div>
          <div class="form-item">
            <label>Reclamo o queja</label>
            <a-select placeholder="Selecciona" v-model="claim">
              <a-select-option value="Reclamo">Reclamo</a-select-option>
              <a-select-option value="Queja">Queja</a-select-option>
            </a-select>
          </div>
        </div>
      </div>
      <div class="form-group">
        <h2>Si elegir Facuración en Materia de Reclamo</h2>
        <div class="form-group-grid">
          <div class="form-item">
            <label>Tipo de Documento</label>
            <a-input v-model="tipoDeDocumento" />
          </div>
          <div class="form-item">
            <label>Ingrese fecha de emisión del documento o recibo</label>

            <a-date-picker :value="date" @change="onChangeDate" />
          </div>
          <div class="form-item">
            <label>Número de factura/boleta</label>
            <a-input v-model="numeroFacturaBoleta" />
          </div>
          <div class="form-item">
            <label>Concepto</label>
            <a-input v-model="concepto" />
          </div>
        </div>
      </div>
      <div class="form-group">
        <h2>Archivos Adjuntos</h2>

        <a-upload :fileList="fileList" :remove="handleRemove" :beforeUpload="beforeUpload">
          <a-button>
            <a-icon type="upload" />Anadir Adjuntos
          </a-button>
        </a-upload>
      </div>
      <CuyButton :onSubmit="onSubmit" title="Enviar" />
    </div>
  </div>
</template>

<script>
import CuyButton from "../../components/CuyButton";
import { mapActions } from "vuex";

export default {
  name: "Claims",
  props: {},
  components: {
    CuyButton
  },

  data() {
    return {
      phone: "",
      dni: "",
      name: "",
      lastName: "",
      calleAvJr: "",
      nombreCalleAvJr: "",
      numero: "",
      departamento: "",
      provincia: "",
      distrito: "",
      notificacionPorEmail: false,
      email: "",
      apelacionReclamo: false,
      numReclamo: "",
      enviarEmailConstancia: false,
      solicitudConcreta: "",
      materiaDeReclamo: "",
      tipoDeDocumento: "",
      numeroFacturaBoleta: "",
      fechaDeEmisionDelDocumentoRecibo: null,
      date: null,
      concepto: "",
      confirmEmail: "",
      claim: "",
      loading: false,
      fileList: []
    };
  },
  methods: {
    ...mapActions(["sendForm"]),

    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    resetForm() {
      this.phone = "";
      this.dni = "";
      this.name = "";
      this.lastName = "";
      this.calleAvJr = "";
      this.nombreCalleAvJr = "";
      this.numero = "";
      this.departamento = "";
      this.provincia = "";
      this.distrito = "";
      this.email = "";
      this.solicitudConcreta = "";
      this.materiaDeReclamo = "";
      this.tipoDeDocumento = "";
      this.numeroFacturaBoleta = "";
      this.fechaDeEmisionDelDocumentoRecibo = null;
      this.concepto = "";
      this.confirmEmail = "";
      this.claim = "";
      this.date = null;
      this.fileList = [];
      this.notificacionPorEmail = false;
      this.enviarEmailConstancia = false;
    },
    async onSubmit() {
      if (this.email != this.confirmEmail) {
        this.$message.error("Los email no coinciden");
      }

      if (
        this.phone == "" ||
        this.dni == "" ||
        this.name == "" ||
        this.lastName == "" ||
        this.calleAvJr == "" ||
        this.nombreCalleAvJr == "" ||
        this.numero == "" ||
        this.departamento == "" ||
        this.provincia == "" ||
        this.distrito == "" ||
        this.email == "" ||
        this.claim == "" ||
        this.solicitudConcreta == "" ||
        this.materiaDeReclamo == "" ||
        this.tipoDeDocumento == "" ||
        this.numeroFacturaBoleta == "" ||
        this.concepto == "" ||
        this.confirmEmail == ""
      ) {
        this.$message.error("Favor llenar todos los campos");
        return;
      }

      if (this.dni.length < 8) {
        this.$message.error("El dni debe tener al menos 8 caracteres");
      }

      const payload = {
        phone: this.phone,
        dni: this.dni,
        name: this.name,
        lastName: this.lastName,

        calleAvJr: this.calleAvJr,
        nombreCalleAvJr: this.nombreCalleAvJr,
        numero: this.numero,
        departamento: this.departamento,
        provincia: this.provincia,
        distrito: this.distrito,

        notificacionPorEmail: this.notificacionPorEmail,
        email: this.email,
        apelacionReclamo: false,
        numReclamo: "",

        claim: this.claim,

        enviarEmailConstancia: this.enviarEmailConstancia,
        solicitudConcreta: this.solicitudConcreta,
        materiaDeReclamo: this.materiaDeReclamo,

        tipoDeDocumento: this.tipoDeDocumento,
        numeroFacturaBoleta: this.numeroFacturaBoleta,
        fechaDeEmisionDelDocumentoRecibo: this.fechaDeEmisionDelDocumentoRecibo,
        concepto: this.concepto
      };

      const data = {
        payload,
        fileList: this.fileList
      };

      try {
        this.loading = true;
        await this.sendForm(data);
      } catch (error) {
        this.$message.error(`Algo salio mal, vuelva a intentar`);
      } finally {
        this.loading = false;
        this.$message.success("Su reclamo ha sido enviado correctamente");
        this.resetForm();
      }
    },
    onChangeNotificacionPorEmail(e) {
      this.notificacionPorEmail = e.target.checked;
    },
    onChangeApelacionReclamo(e) {
      this.apelacionReclamo = e.target.checked;
    },
    onChangeEnviarEmailConstancia(e) {
      this.enviarEmailConstancia = e.target.checked;
    },
    onChangeDni(e) {
      const { value } = e.target;
      const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
      if ((!isNaN(value) && reg.test(value)) || value === "" || value === "-") {
        this.dni = value;
      }
    },
    onChange(e) {
      const { value } = e.target;
      const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
      if ((!isNaN(value) && reg.test(value)) || value === "" || value === "-") {
        this.number = value;
      }
    },
    onChangeDate(date, dateString) {
      this.date = date;
      this.fechaDeEmisionDelDocumentoRecibo = dateString;
    },
    beforeUpload(file) {
      const isImg = file => {
        if (
          file.type === "image/jpeg" ||
          file.type === "image/jpg" ||
          file.type === "image/png" ||
          file.type === "image/bmp" ||
          file.type === "image/gif"
        ) {
          return true;
        }
        return false;
      };

      if (!isImg(file)) {
        this.$message.error("Puedes subir archivos de tipo: jpg,png,bmp,gif");
      }

      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isLt2M) {
        this.$message.error("Las imagenes deben ser maximo de 2MB!");
      }

      if (this.fileList.length > 4) {
        this.$message.error("Solo se pueden subir 5 imagenes");
      }

      if (isImg(file) && isLt2M && this.fileList.length < 5) {
        this.fileList = [...this.fileList, file];
      }

      return false;
    }
  }
};
</script>
<style lang="less">
@import "./styles.less";
</style>
